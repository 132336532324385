<template>
  <b-modal
    v-model="visible"
    title="Editar Conta"
    cancel-variant="outline-secondary"
    centered
    @change="(val) => $emit('update:visible', val)"
  >
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Serviço"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            :disabled="true"
            :value="data.option.name"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Usuário"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="data.username"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Senha"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="data.password"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Email"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="data.email"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mb-1"
      >
        <b-form-group
          label-size="md"
          label-align="left"
          label="Senha do Email"
          label-for="defaultLabel"
        >
          <b-form-input
            id="defaultLabel"
            v-model="data.email_password"
            class="w-100"
            placeholder=""
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="data.user_sn_accounts !== null"
        cols="12"
      >
        <b-form-select
          id="service-type"
          v-model="status"
          :state="status === null ? false : true"
          :options="options"
        >
          <template #first>
            <b-form-select-option
              :value="null"
            >Por favor, selecione um tipo!</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        v-else
        cols="12"
      >
        <b-form-select
          id="service-type"
          value="Padrão"
          :disabled="true"
          :state="true"
          :options="options"
        >
          <template #first>
            <b-form-select-option
              :value="null"
            >Por favor, selecione um tipo!</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-button
        variant="relief-primary"
        :disabled="loading"
        block
        @click.stop="edit()"
      >
        <span v-if="!loading">Salvar</span>
        <b-spinner
          v-else
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BButton, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BSpinner
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BSpinner
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    loading: false,
    status: null,
    options: [
      {
        text: 'Conta Padrão',
        value: 'Padrão'
      },
      {
        text: 'Conta Substituída',
        value: 'Substituída'
      }
    ]
  }),
  mounted () {
    this.status = this.data.user_sn_accounts === null ? null : this.data.user_sn_accounts.status
  },
  methods: {
    async edit () {
      this.loading = true

      const url = `/admin/sn_accounts/${this.data.id}`
      const params = {
        username: this.data.username,
        password: this.data.password,
        email: this.data.email,
        email_password: this.data.email_password,
        status: this.status
      }

      await this.$http.post(url, params)
        .then(() => {
          this.$swal({
            title: 'Conta alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'Fechar',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .finally(() => {
          this.loading = false
          this.$emit('update:visible', false)
        })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
