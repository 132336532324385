<template>
  <b-card>
    <TablePagination
      v-if="table"
      v-model="items"
      url="/admin/sn_accounts/paginate"
      :per-page="10"
      :fields="fields"
      default-filter-by="username"
    >
      <template v-slot:id="data">
        {{ data.items.id }}
      </template>

      <template v-slot:option="data">
        {{ data.items.option.name }}
      </template>

      <template v-slot:purchased_at="data">
        <span v-if="data.items.user_sn_accounts !== null">
          {{ data.items.user_sn_accounts.created_at | date }}
        </span>

        <span v-else>
          Não vendido
        </span>
      </template>

      <template v-slot:user_email="data">
        <span v-if="data.items.user_sn_accounts !== null">
          {{ data.items.user_sn_accounts.user.email }}
        </span>

        <span v-else>
          Não vendido
        </span>
      </template>

      <template v-slot:username="data">
        {{ data.items.username }}
      </template>

      <template v-slot:password="data">
        {{ data.items.password }}
      </template>

      <template v-slot:actions="data">
        <b-button
          variant="relief-primary"
          size="sm"
          @click.stop="toggleDetails(data.items)"
        >
          Detalhes
        </b-button>
      </template>
    </TablePagination>

    <b-modal
      v-if="rowDetailsModal"
      v-model="rowDetailsModal"
      title="Informações da Conta"
      cancel-variant="outline-secondary"
      centered
    >
      ID: {{ rowDetails.id }} <br> <br>
      Serviço: {{ rowDetails.option.name }} <br> <br>
      <span v-if="rowDetails.supplier !== null"> Fornecedor: {{ rowDetails.supplier.name }} <br> <br> </span>
      <span v-if="rowDetails.user_sn_accounts !== null"> Comprado por: {{ rowDetails.user_sn_accounts.user.email }} <br> <br> </span>
      Usuário: {{ rowDetails.username }} <br> <br>
      Senha: {{ rowDetails.password }} <br> <br>
      Email: {{ rowDetails.email }} <br> <br>
      Senha do Email: {{ rowDetails.email_password }} <br> <br>
      Valor: <span v-if="rowDetails.user_sn_accounts !== null && rowDetails.user_sn_accounts.order !== null"> {{ rowDetails.user_sn_accounts.order.amount / rowDetails.user_sn_accounts.order.quantity | currency }} </span> <span v-else> {{ rowDetails.option.accounts_info.value | currency }} </span> <br> <br>
      <span v-if="rowDetails.user_sn_accounts !== null"> Status da Conta: {{ rowDetails.user_sn_accounts.status }} <br> </span>
      <span v-else> Status da Conta: Padrão <br> </span>

      <template #modal-footer>
        <b-button
          variant="relief-primary"
          block
          @click.stop="edit()"
        >
          Editar
        </b-button>
      </template>
    </b-modal>

    <Edit
      v-if="editModal"
      v-model="editModal"
      :data="rowDetails"
    />

  </b-card>
</template>

<script>
import {
  BCard, BButton, BSpinner, BModal
} from 'bootstrap-vue'
import moment from 'moment'
import TablePagination from '@/components/TablePagination/Index.vue'
import Edit from './Edit.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    BButton,
    TablePagination,
    BSpinner,
    BModal,
    Edit
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    },
    date (val) {
      return moment(val).format('DD/MM/YYYY HH:mm:ss')
    }
  },
  data: () => ({
    loading: false,
    rowDetails: false,
    rowDetailsModal: false,
    editModal: false,
    table: true,
    items: [],
    fields: [
      {
        key: 'purchased_at',
        label: 'Comprado em'
      },
      {
        key: 'username',
        label: 'Usuário',
        sortable: true
      },
      {
        key: 'option',
        label: 'Serviço',
        sortable: true
      },
      {
        key: 'user_email',
        label: 'Comprado Por',
        sortable: true
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  }),
  methods: {
    toggleDetails (item) {
      this.rowDetails = JSON.parse(
        JSON.stringify(item)
      )
      this.rowDetailsModal = true
    },
    edit () {
      this.rowDetailsModal = false
      this.editModal = true
    }
  }
}
</script>
