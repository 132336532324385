<template>
  <section>
    <b-tabs
      content-class="mt-1"
      align="center"
    >
      <b-tab title="Gerenciar Contas">
        <Manager />
      </b-tab>

      <b-tab title="Upload De Contas">
        <Upload />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Upload from './Upload/Index.vue'
import Manager from './Manager/Index.vue'

export default {
  components: {
    BTabs,
    BTab,
    Upload,
    Manager
  }
}
</script>
